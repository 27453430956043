const colorMap = {
  red: 'rgb(238, 58, 58)',
  write: 'rgb(238, 187, 58)',
  delete: 'rgb(238, 127, 58)',
  read: 'rgb(58, 145, 238)',
  green: 'rgb(148, 238, 58)',
  serverlessFunction: 'rgb(238, 58, 202)',
  purple: 'rgb(133, 58, 238)',
  render: 'rgb(167, 167, 167)',
  white: 'rgb(255, 255, 255)',
};

type Colors = keyof typeof colorMap;

export const log = (colorOrAction: Colors, message: string) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(`%c ${message}`, `color: ${colorMap[colorOrAction]}`);
  }
};
