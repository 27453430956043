// import * as Sentry from '@sentry/react';

import { DBUser, TransformedUser } from '@gutsjs/guts-shared';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { auth, firebaseAnalytics } from '../lib/firebase';

import { FirestoreStateManagerContext } from './FirestoreStateManager';
import { log } from '../lib/log';
import { onAuthStateChanged } from 'firebase/auth';
import { setUserId } from '@firebase/analytics';
import { transformUser } from '../lib/transformUser';

type AuthenticationProps = {
  loggedInUser: TransformedUser | undefined;
  authHasLoaded: boolean;
  authListenerDisabled: boolean;
  setAuthListenerDisabled: (disable: boolean) => void;
  clearAuth: () => void;
};

export const AuthenticationContext = React.createContext<AuthenticationProps>(
  {} as AuthenticationProps
);

export const AuthenticationProvider = ({ children, ipCurrency }: any) => {
  const [authHasLoaded, setAuthHasLoaded] = useState(false);
  const [authListenerDisabled, setAuthListenerDisabled] = useState(false);
  const [loggedInUserId, setLoggedInUserId] = useState<string>();
  const { watchObject, getObjectIfExistsSync, nukeLocalStateData } = useContext(
    FirestoreStateManagerContext
  );

  const loggedInUser = getObjectIfExistsSync<TransformedUser>(
    'users',
    loggedInUserId
  );

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (authUser) => {
      log('green', 'Auth Changed');
      if (authUser?.uid) {
        log('green', 'User logged in');
        if (!authListenerDisabled) {
          log('green', 'Auth Listener Enabled');
          setLoggedInUserId(authUser?.uid);
          const getUser = async () => {
            await watchObject('users', authUser?.uid, transformUser);
            log('green', 'User fetched. Setting LoggedInUser');
            setAuthHasLoaded(true);
          };
          getUser();
        } else {
          log('green', 'Auth Listener Disabled, setting authHasLoaded = true');
          setAuthHasLoaded(true);
        }
      } else {
        log('green', 'No User, setting loggedInUser to undefined');
        setLoggedInUserId(undefined);
        setAuthHasLoaded(true);
      }
    });
    return () => unsubscribe();
  }, [authListenerDisabled]);

  useEffect(() => {
    setUserId(firebaseAnalytics(), loggedInUserId || '');
  }, [loggedInUserId]);

  const clearAuth = () => {
    log('green', 'Clearing Auth Context');
    setLoggedInUserId(undefined);
    nukeLocalStateData();
  };

  return (
    <AuthenticationContext.Provider
      value={{
        loggedInUser,
        authListenerDisabled,
        setAuthListenerDisabled,
        authHasLoaded,
        clearAuth,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};
