import { Timestamp as TimestampType, getFirestore } from 'firebase/firestore';

import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { initializeApp } from 'firebase/app';

const firebase = initializeApp({
  apiKey: 'AIzaSyCMwP2iNIsN9g-NpXvW7prf--twzWt8IBc',
  authDomain: 'guts-9191c.firebaseapp.com',
  projectId: 'guts-9191c',
  storageBucket: 'guts-9191c.appspot.com',
  messagingSenderId: '442321306765',
  appId: '1:442321306765:web:f13c4a37e79c6158b24ec4',
  measurementId: 'G-QTMQC18QMH',
});

const db = getFirestore(firebase);
const auth = getAuth(firebase);

const storage = getStorage(firebase);
const firebaseAnalytics = () => getAnalytics(firebase);

const Timestamp = TimestampType;

export { db, auth, firebase, Timestamp, storage, firebaseAnalytics };
