import {
  DBUser,
  SiteMemberData,
  TransformedSiteMemberData,
  TransformedUser,
} from '@gutsjs/guts-shared';

export const transformUser = async (rawDoc: any) => {
  const userObj: TransformedUser = {
    id: rawDoc.id,
    ...(rawDoc.data() as DBUser),
    get formattedName() {
      return `${this.firstName ? this.firstName + ' ' : ''}${
        this.lastName ? this.lastName : ''
      }`.trim();
    },
  };

  return userObj;
};

export const transformSiteMember = async (rawDoc: any) => {
  const userObj: TransformedSiteMemberData = {
    id: rawDoc.id,
    ...(rawDoc.data() as SiteMemberData),
  };

  return userObj;
};
