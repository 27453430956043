import {
  DocumentData,
  DocumentSnapshot,
  Firestore,
} from 'firebase/firestore/lite';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Unsubscribe,
  deleteField,
  doc,
  getDoc,
  onSnapshot,
  updateDoc,
} from 'firebase/firestore';

import { TransformedUser } from '@gutsjs/guts-shared';
// import equal from 'fast-deep-equal/es6';
import { log } from '../lib/log';

type ObjectData = {
  [key: string]: any;
};

type FirestoreStateManagerProps = {
  nukeLocalStateData: () => void;
  removeObjectFromState: (collectionPath: string, objectId: string) => void;
  updateObject: (
    collectionPath: string,
    objectId: string,
    newFields: Partial<ObjectData>
  ) => Promise<ObjectData | undefined>;
  getObject: (
    collectionPath: string,
    objectId: string,
    refresh?: boolean,
    transformer?: TransformerFunction
  ) => Promise<ObjectData | null | undefined>;
  getObjectIfExistsSync: <T>(
    collectionPath: string,
    objectId?: string | undefined
  ) => T | undefined;
  collectionsLoading: string[];
  data: Data<ObjectData>;
  watchObject: (
    collectionPath: string,
    objectId: string,
    transformer?: TransformerFunction
  ) => void;
  stopWatchingObject: (collectionPath: string, objectId: string) => void;
  stopWatchingAll: () => void;
};

type Data<ObjectData> = {
  [collectionPath: string]: {
    [objectId: string]: ObjectData;
  };
};

type TransformerFunction = (
  doc: DocumentSnapshot<DocumentData>
) => Promise<ObjectData>;

export const FirestoreStateManagerContext =
  React.createContext<FirestoreStateManagerProps>(
    {} as FirestoreStateManagerProps
  );

type FirestoreStateManagerProviderProps = {
  firestore: Firestore;
  sessionWriteLimit?: number;
};

type ObjectLocation = {
  collectionPath: string;
  objectId: string;
};

type ObjectListener = ObjectLocation & {
  unsubscribe: Unsubscribe;
};

function findMatchingObjectLocation<T extends ObjectLocation>(
  arr: T[] | undefined,
  collectionPath: string,
  objectId: string
) {
  return arr?.find(
    ({ collectionPath: listenerCollectionPath, objectId: listenerObjectId }) =>
      listenerCollectionPath === collectionPath && objectId === listenerObjectId
  );
}

const stopListeningToObject = (listener: ObjectListener) => {
  listener.unsubscribe();
  log(
    'green',
    `[onSnapshot] Object watch subscription removed for: ${listener.collectionPath}/${listener.objectId}`
  );
};

export const FirestoreStateManagerProvider: React.FC<
  FirestoreStateManagerProviderProps
> = ({ children, firestore, sessionWriteLimit = 1000 }) => {
  const [data, setData] = useState<Data<ObjectData>>({}); // TODO type any???!!!
  const [collectionsLoading, setCollectionsLoading] = useState<string[]>([]);

  const realTimeFetchList = useRef<ObjectLocation[]>([]);
  const { current: dataRef } = useRef<Data<ObjectData>>({});
  const documentListeners = useRef<ObjectListener[]>([]);
  const writeLimitGuard = useRef(sessionWriteLimit);

  const nukeLocalStateData = useCallback(() => setData({}), []);

  const getObjectIfExistsSync = useCallback(
    <T,>(collectionPath: string, objectId: string | undefined) => {
      return objectId && data[collectionPath] && data[collectionPath][objectId]
        ? (data[collectionPath][objectId] as T)
        : undefined;
    },
    [data]
  );

  const watchObject: FirestoreStateManagerProps['watchObject'] = useCallback(
    (collectionPath, objectId, transformer) => {
      if (
        !findMatchingObjectLocation(
          documentListeners?.current,
          collectionPath,
          objectId
        )
      ) {
        log(
          'green',
          '[onSnapshot] Object watch subscription created for:' +
            collectionPath +
            '/' +
            objectId
        );
        const listenerUnsubscribe = onSnapshot(
          doc(firestore, collectionPath, objectId),
          async (rawDoc) => {
            if (!rawDoc.exists) {
              log(
                'red',
                '[onSnapshot Err] Object does not exist at:' +
                  collectionPath +
                  '/' +
                  objectId
              );
              return null;
            }

            const object = transformer
              ? await transformer(rawDoc)
              : rawDoc.data();

            if (!object) {
              log(
                'red',
                '[onSnapshot Err] Object document data or transformer at:' +
                  collectionPath +
                  '/' +
                  objectId +
                  ' returned an invalid object'
              );
              return null;
            }

            if (!dataRef[collectionPath]) {
              dataRef[collectionPath] = {};
            }

            dataRef[collectionPath][objectId] = object;

            log(
              'white',
              '[onSnapshot] Watched Object updated: ' +
                collectionPath +
                '/' +
                objectId
            );
            setData({ ...dataRef });
          },
          console.error
        );
        documentListeners.current.push({
          objectId,
          collectionPath,
          unsubscribe: listenerUnsubscribe,
        });
      }
    },
    [] // @TODO does the ref really need to be in here? Its a ref so it should be mutable regardless of render.. RIGHT? RIGHT?
  );

  const stopWatchingAll: FirestoreStateManagerProps['stopWatchingAll'] =
    useCallback(() => {
      for (const listenerRef of documentListeners?.current) {
        stopListeningToObject(listenerRef);
      }
      documentListeners.current = [];
      return;
    }, []);

  const stopWatchingObject: FirestoreStateManagerProps['stopWatchingObject'] =
    useCallback((collectionPath, objectId) => {
      // if empty ID, remove all listeners
      if (!collectionPath) {
        log('red', '[onSnapshot Err] collectionPath not provided');
        return;
      }

      if (!objectId) {
        log('red', '[onSnapshot Err] objectId not provided');
        return;
      }

      const listenerRef = findMatchingObjectLocation(
        documentListeners?.current,
        collectionPath,
        objectId
      );
      if (!listenerRef) {
        log(
          'read',
          `[onSnapshot] Failed to find a listener for ${collectionPath}/${objectId} to stop watching`
        );
        return;
      }

      stopListeningToObject(listenerRef);

      documentListeners.current = documentListeners?.current?.filter(
        ({
          collectionPath: listenerCollectionPath,
          objectId: listenerObjectId,
        }) =>
          listenerCollectionPath !== collectionPath ||
          objectId !== listenerObjectId
      );
    }, []);

  const getObject: FirestoreStateManagerProps['getObject'] = async (
    collectionPath,
    objectId,
    refresh,
    transformer
  ) => {
    if (dataRef[collectionPath] && dataRef[collectionPath][objectId]) {
      if (refresh) {
        return await fetchObject(collectionPath, objectId, transformer);
      } else {
        return dataRef[collectionPath][objectId];
      }
    } else {
      return await fetchObject(collectionPath, objectId, transformer);
    }
  };

  const removeObjectFromState: FirestoreStateManagerProps['removeObjectFromState'] =
    (collectionPath, objectId) => {
      if (dataRef[collectionPath]) {
        delete dataRef[collectionPath][objectId];
      }
      setData(dataRef);
    };

  const updateObject: FirestoreStateManagerProps['updateObject'] = async (
    collectionPath,
    objectId,
    newFields
  ) => {
    if (!dataRef[collectionPath][objectId]) {
      log(
        'red',
        `[Update Err] Object not found in data state: ${collectionPath}/${objectId} Make sure you 'get' the object first.`
      );
      return;
    }

    setCollectionsLoading([...collectionsLoading, collectionPath]);

    dataRef[collectionPath][objectId] = {
      ...dataRef[collectionPath][objectId],
      ...newFields,
    };

    log('write', `[Write] Updating object: ${collectionPath}/${objectId}`);

    writeLimitGuard.current--;
    if (writeLimitGuard.current < 1) {
      throw new Error('Write limit exceeded');
    }

    // if any field is undefined, the assumed intention is to remove it from DB.
    // This is also because, firestore will get upset with us if we send it a value of undefined. He don't like it.
    for (const key in newFields) {
      if (newFields[key as keyof typeof newFields] === undefined) {
        newFields[key as keyof typeof newFields] = deleteField();
      }
    }

    await updateDoc(doc(firestore, collectionPath, objectId), newFields);

    setCollectionsLoading(
      collectionsLoading.filter((i) => i !== collectionPath)
    );

    // only force state update if its not being watched otherwise let the listener update the state
    const listenerRef = findMatchingObjectLocation(
      documentListeners?.current,
      collectionPath,
      objectId
    );
    if (!listenerRef) {
      setData({ ...dataRef });
    }
    return dataRef[collectionPath][objectId];
  };

  const fetchObject = async (
    collectionPath: string,
    objectId: string,
    transformer?: TransformerFunction
  ) => {
    if (
      findMatchingObjectLocation(
        realTimeFetchList?.current,
        collectionPath,
        objectId
      )
    ) {
      return null;
    } else {
      realTimeFetchList.current.push({ collectionPath, objectId });
    }
    log('read', `[Fetch (GET)] Object: ${collectionPath}/${objectId}`);

    setCollectionsLoading([...collectionsLoading, collectionPath]);
    const rawDoc = await getDoc(doc(firestore, collectionPath, objectId));

    if (!rawDoc.exists) {
      log(
        'red',
        '[Fetch (GET) Err] Object does not exist at:' +
          collectionPath +
          '/' +
          objectId
      );
      return null;
    }

    const object = transformer ? await transformer(rawDoc) : rawDoc.data();

    if (!object) {
      log(
        'red',
        '[Fetch (GET) Err] Object document data or transformer at:' +
          collectionPath +
          '/' +
          objectId +
          ' returned an invalid object'
      );
      return null;
    }

    if (!dataRef[collectionPath]) {
      dataRef[collectionPath] = {};
    }

    dataRef[collectionPath][objectId] = object;

    if (!dataRef[collectionPath]) {
      dataRef[collectionPath] = {};
    }

    dataRef[collectionPath][objectId] = object;

    realTimeFetchList.current = realTimeFetchList.current.filter(
      ({
        collectionPath: listenerCollectionPath,
        objectId: listenerObjectId,
      }) =>
        listenerCollectionPath !== collectionPath ||
        objectId !== listenerObjectId
    );
    if (realTimeFetchList.current.length === 0) {
      setData({ ...dataRef });
      setCollectionsLoading([]);
    }
    return object;
  };

  useEffect(() => {
    // on unmount, cancel all subscriptions
    return () => {
      for (const item of documentListeners?.current) {
        stopListeningToObject(item);
      }
      documentListeners.current = [];
    };
  }, []);

  return (
    <FirestoreStateManagerContext.Provider
      value={{
        nukeLocalStateData,
        stopWatchingAll,
        getObject,
        watchObject,
        stopWatchingObject,
        data,
        updateObject,
        collectionsLoading,
        getObjectIfExistsSync,
        removeObjectFromState,
      }}
    >
      {children}
    </FirestoreStateManagerContext.Provider>
  );
};
