import '../styles/global.css';

import { AppProps } from 'next/app';
import { AuthenticationProvider } from '../contexts/AuthenticationContext';
import { FirestoreStateManagerProvider } from '../contexts/FirestoreStateManager';
import Head from 'next/head';
import { NextUIProvider } from '@nextui-org/react';
import { Toaster } from 'react-hot-toast';
import { createTheme } from '@nextui-org/react';
import { db } from '../lib/firebase';

const darkTheme = createTheme({
  type: 'dark',
  theme: {
    colors: {
      text: 'var(--guts-color-text)',
      selection: 'var(--guts-color-medium)',
    },
  },
});

export default function App({ Component, pageProps, err }: AppProps | any) {
  return (
    <>
      <Head>
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1, viewport-fit=cover'
        ></meta>
      </Head>
      <FirestoreStateManagerProvider firestore={db}>
        <AuthenticationProvider>
          <NextUIProvider theme={darkTheme}>
            <Component {...pageProps} err={err} />
          </NextUIProvider>
        </AuthenticationProvider>
      </FirestoreStateManagerProvider>

      <Toaster
        toastOptions={{
          style: {
            borderRadius: '10px',
            background: '#292929',
            color: 'rgb(239, 253, 255)',
            fontFamily: 'Fira Mono',
          },
        }}
      />
    </>
  );
}
